<template>
  <div class="file-name-and-size">
    <div class="file-name-and-size__logo-wrapper">
      <i class="logo-wrapper__icon icon-file" />
    </div>
    <div class="file-name-and-size__file-metadata">
      <span class="file-metadata__title">
        {{ filename }}
      </span>
      <span class="file-metadata__size"> {{ humanReadableFileSize }} </span>
    </div>
  </div>
</template>

<script lang="ts">
import { filesize } from 'filesize'

export default {
  name: 'FilenameAndSize',
  props: {
    filename: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 0
    }
  },
  computed: {
    humanReadableFileSize() {
      return filesize(this.size)
    }
  }
}
</script>

<style lang="scss" scoped>
.file-name-and-size {
  display: flex;
  align-items: center;

  &__logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: $bg-primary;
    border-radius: 50%;
    margin-right: $margin-mini;

    .logo-wrapper {
      &__icon {
        @include font-icon;
        @include font-size(18px);
      }
    }
  }

  &__file-metadata {
    display: flex;
    flex-direction: column;

    .file-metadata {
      &__title {
        @include font-text;
        @include font-size($font-size-small);
      }

      &__size {
        @include font-text;
        @include font-size($font-size-small);
        color: $text-tertiary;
        text-align: left;
      }
    }
  }
}
</style>
